<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="flex flex-col items-center justify-center">
    <div class="flex justify-center py-3 logo-container w-full">
      <a href="https://app.freshclinics.com"
        ><img
          :src="require('../../../../../assets/images/fcLOgo.svg')"
          alt="Fresh Clinics"
          width="251"
          height="56"
      /></a>
    </div>
    <div class="px-5 md:px-0 lg:px-0 login-box">
      <div
        class="p-6 md:p-8 lg:p-8 my-10 md:my-16 lg:my-16 login-tabs-container"
      >
        <div class="form-heading">
          <a
            href="#"
            style="font-weight: 400; font-size: 14px"
            class="flex justify-start items-center -ml-2 mb-3"
            @click="back"
          >
            <chevron-left-icon
              class="custom-class"
              size="1.5x"
            ></chevron-left-icon>
            Back</a
          >
          <p style="font-size: 18px">Existing Members Resources</p>
          <div
            class="
              rounded-lg
              bg-white
              p-3
              mt-5
              w-full
              block
              cursor-pointer
              make-it-slow
            "
            @click="changeAddress"
          >
            <p class="green-700">Change of Address</p>
            <p class="gray">Need to change your clinic address? Click here!</p>
          </div>

          <div
            class="
              rounded-lg
              bg-white
              p-3
              mt-5
              w-full
              block
              cursor-pointer
              make-it-slow
            "
            @click="newNurse"
          >
            <p class="green-700">New Nurse Sign up</p>
            <p class="gray">Looking to setup a new nurse? Click here</p>
          </div>

          <div
            class="
              rounded-lg
              bg-white
              p-3
              mt-5
              w-full
              block
              cursor-pointer
              make-it-slow
            "
            @click="newClinic"
          >
            <p class="green-700">New Clinic Sign up</p>
            <p class="gray">Have a new clinic to sign up? Click here</p>
          </div>

          <div class="mt-5 text-center">
            <p class="gray">
              Have an account?
              <a class="cursor-pointer create-account" @click="register"
                >Login here
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { ChevronLeftIcon } from "vue-feather-icons";
export default {
  components: {
    ChevronLeftIcon,
  },
  data() {
    return {
      test: false,
    };
  },
  methods: {
    login() {
      this.$router.push("/login");
    },
    store() {
      window.location.href = "https://orders.freshclinics.com.au/wp-login.php";
    },
    register() {
      this.$router.push("/login");
    },
    back() {
      this.$router.push("/join-fresh-clinics");
    },
    changeAddress() {
      window.location.href = "https://www.freshclinics.com.au/change-of-address";
    },
    newNurse() {
      window.location.href = "https://www.freshclinics.com.au/nurse-sign-up-2";
    },
    newClinic() {
      window.location.href = "https://www.freshclinics.com.au/clinic-sign-up-2";
    },
  },
};
</script>

<style lang="scss">
.login-box {
  width: 407px;
}

.login-tabs-container {
  height: auto;
  margin-right: auto;
  margin-left: auto;

  border-radius: 6px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: white;

  .con-tab {
    padding-bottom: 14px;
  }

  .con-slot-tabs {
    margin-top: 1rem;
  }
}

.form-heading {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #0e4333;
}

.logo-container {
  border-bottom: 2px solid #ebe9f1;
}

.custom-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
}

p.gray {
  color: #6e6b7b;
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
  letter-spacing: -0.2px;
}

.green-700 {
  color: #0e4333;
  font-size: 14px;
}

.make-it-slow {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease-in-out;
}

/* Transition to a bigger shadow on hover */
.make-it-slow:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

/* The fast way */
.make-it-fast {
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
}

/* Pre-render the bigger shadow, but hide it */
.make-it-fast::after {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

/* Transition to showing the bigger shadow on hover */
.make-it-fast:hover::after {
  opacity: 1;
}

a.create-account {
  padding-bottom: 1px;
  border-bottom: solid 1px #47e7b8;
}

@media only screen and (max-width: 375px) {
  .login-box {
    width: 100% !important;
  }
}
</style>
